import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import Thanks from "../components/thanks/Thanks"
import ThanksV2 from "../components/thanks/Thanks-v2"


const ThankYouPage = () => {

  return (
    <Layout className="thanksPage" >
      <Seo title="Dziękujemy za kontakt" />
      <ThanksV2 />
    </Layout>
  )
}

export default ThankYouPage
