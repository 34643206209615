import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Heading from "../heading/Heading"
import "./style.scss"

const Thanks = () => {
  const data = useStaticQuery(
    graphql`
      query ThxPageData {
        contentJson(type: { eq: "thxPage" }) {
          heading
          heading2
          description
          images {
            id
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
          buttonReport {
            text
            link
          }
          buttonWebinar {
            text
            link
          }
        }
      }
    `
  )
  const {
    heading,
    heading2,
    description,
    buttonReport,
    buttonWebinar,
    images,
  } = data.contentJson

  return (
    <section className="thanks">
      <div className="thanks__background">
        <GatsbyImage image={images?.childImageSharp.gatsbyImageData} alt="" />
      </div>
      <div className="thanks__content">
        <Heading
          text={heading}
          type="2"
          size="XL"
          align="center"
          className="thanks__heading1"
        />
        <Heading
          text={heading2}
          type="2"
          size="Big"
          align="center"
          className="thanks__headin2"
        />
       
      </div>
    </section>
  )
}
export default Thanks
